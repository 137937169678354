@import '~theme/variables';

.card {
  background-color: $color-neutral-06;
  display: flex;
  flex-direction: column;
  padding: 0 0 $space-l 0;
  border: 0.1rem solid $color-neutral-04;

  width: 100%;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;

  @include breakpoint(sm) {
    flex-direction: row;
    padding: $space-l;
  }
  @include breakpoint(md) {
    &.isInverted {
      flex-direction: row-reverse;

      .image {
        @include breakpoint(sm) {
          margin-right: 0;
          margin-left: $space-l;
        }
      }
    }
  }
}

.container {
  padding-left: $space-s;
  padding-right: $space-s;

  @include breakpoint(sm) {
    padding-left: $space-xl;
    padding-right: $space-xl;
  }

  @include breakpoint(md) {
    padding-left: $space-xxl;
    padding-right: $space-xxl;
  }

  @include breakpoint(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.col {
  padding: 0;
}

.image {
  border-radius: $border-radius-03 $border-radius-03 0 0;
  object-fit: cover;
  margin-bottom: $space-m;

  @include breakpoint(sm) {
    width: 22rem;
    height: 22rem;
    margin-bottom: 0;
    margin-right: $space-l;
    border-radius: $border-radius-03;
  }
}

.title {
  margin-bottom: $space-s;
}

.buttonsContainer {
  margin-top: $space-s;
  a {
    margin-right: $space-s;
  }
}
